import React from "react";

const RunningText = () => {
  return (
    <div className="overflow-hidden font-molot bg-[#112483] whitespace-nowrap text-white py-2 lg:py-3 italic text-4xl lg:text-2xl max-w-full ">
      <div className="flex animate-scroll space-x-2">
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
        <span>$GEKU</span>
      </div>
    </div>
  );
};

export default RunningText;
