import React from "react";
import { FaTwitter, FaTelegramPlane } from "react-icons/fa";
import NavbarLogo from "../assets/images/logo3.png";

const Navbar = () => {
  return (
    <div className="font-molot ">
      {/* Main Navbar */}
      <header className="bg-transparent shadow-md">
        <div className="container mx-auto px-16 flex text-2xl justify-between items-center py-3">
          {/* Logo Navbar */}
          <a href="/" rel="noopener noreferrer">
            <img
              src={NavbarLogo}
              className="bg-[#112483] rounded-full md:w-14 md:h-14 w-10 h-10"
              alt="Dragon Ball Z logo"
            />
          </a>

          {/* Social Icons */}
          <div className="flex space-x-4 text-[#112483]">
            <a
              className="hover:text-[#9690EF]"
              href="https://x.com/gekuthecoin"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              className="hover:text-[#9690EF]"
              href=" "
              rel="noopener noreferrer"
            >
              <FaTelegramPlane />
            </a>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
