import React from "react";

const Footer = () => {
  return (
    <footer className="w-full py-4 bottom-0 border-t border-gray-700">
      <div className="container mx-auto text-center ">
        <p className="font-bold font-molo text-sm">
          <span>© 2024 </span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
