import React from "react";
import Geku from "../assets/images/GekuV1.png";
import Marquee from "./Marquee";
import MiniNavbar from "./MiniNavbar";

function Page1() {
  return (
    <>
      <MiniNavbar />
      <div
        className="flex items-center justify-center px-10 max-w-full"
        // style={{
        //   backgroundImage: `url(${require("../assets/images/OIG3.jpg")})`,
        // }}
      >
        <div className="flex flex-col lg:flex-row container mx-auto items-center lg:items-start w-full pt-4 space-y-4">
          <h1 className="md:text-8xl text-7xl  pt-4 font-molot text-[#112483] lg:hidden ">
            $GEKU
          </h1>
          <p className="text-center md:text-start text-sm md:text-lg md:hidden flex justify-center">
            CA: So11111111111111111111111111111111111111112
          </p>
          <div className="md:w-1/2 h-auto relative  justify-center lg:justify-start hidden lg:flex">
            <img src={Geku} alt="Aped Hero" className="w-[75%]" />
          </div>
          <div className="lg:w-1/2 w-full text-center lg:text-left lg:mt-0 mx-6 md:mx-0 space-y-4 clear-none">
            <img
              src={Geku}
              alt="Aped Hero"
              className="w-[55%] float-left lg:hidden md:flex"
            />

            <h1 className="text-7xl lg:text-9xl lg:text-[8rem] font-molot text-[#112483] hidden lg:flex">
              $GEKU
            </h1>

            <div className="text-md md:text-2xl space-y-3 text-justify font-londrina">
              <p>
                Geku is a powerful Namek warrior and one of Goku's friends.
                known for his gecko-humanoid appearance with purple color.
                originally from namek's planet where the humanoid race
                originated, such as Piccolo, Kami and Dende. Geku is a strong
                warrior equal to Goku even though they live on different
                planets. When Namek was about to be destroyed by Frieza, Geku
                and other warriors managed to hold Frieza back until Goku came
                to help.
              </p>

              <p>
                Geku and Goku then fought together to defeat Frieza, after
                successfully defeating Frieza, Geku helped Goku and the people
                of Namek to escape from the big explosion. Since then Geku has
                been a good friend of Goku until his death.
              </p>

              <p className="md:pt-10 py-8 text-center md:text-start text-sm md:text-lg hidden md:flex ">
                CA: So11111111111111111111111111111111111111112
              </p>
            </div>
          </div>
        </div>
      </div>
      <Marquee />
    </>
  );
}

export default Page1;
