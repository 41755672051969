import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import Navbar from "./pages/Navbar";
import Page1 from "./pages/Page1";
import Page2 from "./pages/Page2";
import Footer from "./pages/Footer";

function App() {
  // Ref untuk Page1
  const page1Ref = useRef(null);
  const [showPage2, setShowPage2] = useState(false);

  useEffect(() => {
    // Scroll ke Page1 saat pertama kali komponen dimuat
    if (page1Ref.current) {
      page1Ref.current.scrollIntoView({ behavior: "smooth" });
    }

    // Fungsi untuk mengamati apakah Page2 perlu ditampilkan
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowPage2(true); // Render Page2 saat viewport mencapai Page1
          observer.disconnect(); // Hentikan pengamatan setelah Page2 dirender
        }
      },
      { threshold: 0.5 } // Set threshold agar Page2 muncul saat Page1 hampir selesai di-scroll
    );

    if (page1Ref.current) {
      observer.observe(page1Ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div>
      <Navbar />
      <div ref={page1Ref}>
        <Page1 />
      </div>
      {showPage2 && <Page2 />}{" "}
      {/* Page2 hanya dirender ketika showPage2 adalah true */}
      <Footer />
    </div>
  );
}

export default App;
